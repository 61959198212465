import {OUT_OF_STOCK_NOTIFICATION, OUT_OF_STOCK_INGREDIENT_NOTIFICATION, OUT_OF_STOCK_PACKAGING_NOTIFICATION, OVERHEADS_NOTIFICATION, DEBTORS_NOTIFICATION} from "../constants/Notifications";

export const outOfStockNotification = (products) => {
    return {
        type: OUT_OF_STOCK_NOTIFICATION,
        payload: products
    }
};

export const outOfStockIngredientNotification = (ingredients) => {
    return {
        type: OUT_OF_STOCK_INGREDIENT_NOTIFICATION,
        payload: ingredients
    }
};

export const outOfStockPackagingNotification = (packages) => {
    return {
        type: OUT_OF_STOCK_PACKAGING_NOTIFICATION,
        payload: packages
    }
};

export const overheadsNotification = (overheads) => {
    return {
        type: OVERHEADS_NOTIFICATION,
        payload: overheads
    }
};

export const debtorsNotification = (ingredients) => {
    return {
        type: DEBTORS_NOTIFICATION,
        payload: ingredients
    }
};
