import {
  CalculatorOutlined,
  CodeSandboxOutlined,
  DashboardOutlined,
  DeleteOutlined,
  DollarOutlined,
  ExperimentOutlined,
  FireOutlined,
  FundOutlined,
  IdcardOutlined,
  LaptopOutlined,
  OrderedListOutlined,
  PlusCircleOutlined,
  ShopOutlined,
  ShoppingCartOutlined,
  SolutionOutlined,
  TeamOutlined,
  UserAddOutlined,
  CalendarOutlined,
} from "@ant-design/icons";
import Utils from "utils";

const dashBoardNavTree = [
  {
    key: "dashboards",
    path: "/app/dashboards",
    title: "sidenav.dashboard",
    icon: DashboardOutlined,
    breadcrumb: false,
    submenu: [
      {
        key: "dashboards-sales",
        path: "/app/dashboards/sales",
        title: "sidenav.dashboard.sales",
        icon: FundOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "cash-register",
        path: "/app/caja/efectivo",
        title: "sidenav.finance.registers.cash",
        icon: CalculatorOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "cash-bank",
        path: "/app/caja/banco",
        title: "sidenav.finance.registers.bank",
        icon: LaptopOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "list-registers",
        path: "/app/caja/listado",
        title: "sidenav.finance.registers.list",
        icon: CalendarOutlined,
        breadcrumb: true,
        submenu: [],
      },
    ],
  },
];

/* CUSTOM ROUTES */

const recipesNavTree = [
  {
    key: "recipe",
    path: "/app/recetas",
    title: "sidenav.recipes",
    icon: DashboardOutlined,
    breadcrumb: true,
    submenu: [
      {
        key: "recipe-show",
        path: "/app/recetas",
        title: "sidenav.recipes.show",
        icon: OrderedListOutlined,
        breadcrumb: false,
        submenu: [],
      },
    ],
  },
];

if (!Utils.isChef()) {
  recipesNavTree[0].submenu.push({
    key: "recipe-new",
    path: "/app/recetas/nueva",
    title: "sidenav.recipes.new",
    icon: PlusCircleOutlined,
    breadcrumb: false,
    submenu: [],
  });
}

const productsNavTree = [
  {
    key: "products",
    path: "/app/productos",
    title: "sidenav.products",
    icon: DashboardOutlined,
    breadcrumb: true,
    submenu: [
      {
        key: "product-list",
        path: "/app/productos/listado",
        title: "sidenav.products.list",
        icon: OrderedListOutlined,
        breadcrumb: true,
        submenu: [],
      },
      {
        key: "product-create",
        path: "/app/productos/nuevo",
        title: "sidenav.products.new",
        icon: PlusCircleOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "promotions",
        path: "/app/promociones/listado",
        title: "sidenav.promotions.list",
        icon: OrderedListOutlined,
        breadcrumb: true,
        submenu: [],
      },
      {
        key: "product-waste",
        path: "/app/productos/desperdicios",
        title: "sidenav.products.waste",
        icon: DeleteOutlined,
        breadcrumb: false,
        submenu: [],
      },
    ],
  },
];

const ordersNavTree = [
  {
    key: "orders",
    path: "/app/pedidos",
    title: "sidenav.orders",
    icon: DashboardOutlined,
    breadcrumb: false,
    submenu: [
      {
        key: "order-list",
        path: "/app/pedidos/listado",
        title: "sidenav.orders.list",
        icon: ShoppingCartOutlined,
        breadcrumb: true,
        submenu: [],
      },
      {
        key: "order-create",
        path: "/app/pedidos/nuevo",
        title: "sidenav.orders.new",
        icon: DollarOutlined,
        breadcrumb: false,
        submenu: [],
      }
    ],
  },
];

const providersNavTree = [
  {
    key: "providers",
    path: "/app/proveedores",
    title: "sidenav.providers",
    icon: SolutionOutlined,
    breadcrumb: false,
    submenu: [
      {
        key: "providers-list",
        path: "/app/proveedores/listado",
        title: "sidenav.providers.list",
        icon: SolutionOutlined,
        breadcrumb: true,
        submenu: [],
      },
      {
        key: "providers-create",
        path: "/app/proveedores/nuevo",
        title: "sidenav.providers.new",
        icon: UserAddOutlined,
        breadcrumb: false,
        submenu: [],
      },
    ],
  },
];

const purchaseNavTree = [
  {
    key: "purchase",
    path: "/app/compras",
    title: "sidenav.purchases",
    icon: IdcardOutlined,
    breadcrumb: false,
    submenu: [
      {
        key: "purchases-list",
        path: "/app/compras/listado",
        title: "sidenav.purchases.list",
        icon: IdcardOutlined,
        breadcrumb: true,
        submenu: [],
      },
      {
        key: "purchases-create",
        path: "/app/compras/nuevo",
        title: "sidenav.purchases.new",
        icon: ShopOutlined,
        breadcrumb: false,
        submenu: [],
      },
    ],
  },
];

const usersNavTree = [
  {
    key: "users",
    path: "/app/usuarios",
    title: "sidenav.users",
    icon: TeamOutlined,
    breadcrumb: false,
    submenu: [
      {
        key: "users-list",
        path: "/app/usuarios/listado",
        title: "sidenav.users.list",
        icon: TeamOutlined,
        breadcrumb: true,
        submenu: [],
      },
    ],
  },
];

const clientsNavTree = [
  {
    key: "clients",
    path: "/app/clientes",
    title: "sidenav.clients",
    icon: IdcardOutlined,
    breadcrumb: false,
    submenu: [
      {
        key: "clients-list",
        path: "/app/clientes/listado",
        title: "sidenav.clients.list",
        icon: IdcardOutlined,
        breadcrumb: true,
        submenu: [],
      },
      {
        key: "debtors-list",
        path: "/app/clientes/deudores",
        title: "sidenav.clients.debtors",
        icon: IdcardOutlined,
        breadcrumb: true,
        submenu: [],
      },
    ],
  },
];

const consumablesNavTree = [
  {
    key: "consumables",
    path: "/app/insumos",
    title: "sidenav.consumables",
    icon: FireOutlined,
    breadcrumb: true,
    submenu: [
      {
        key: "consumables-list",
        path: "/app/insumos/listado",
        title: "sidenav.consumables.list",
        icon: FireOutlined,
        breadcrumb: true,
        submenu: [],
      },
      {
        key: "ingredients-list",
        path: "/app/ingredientes/listado",
        title: "sidenav.consumables.ingredients",
        icon: ExperimentOutlined,
        breadcrumb: true,
        submenu: [],
      },
      {
        key: "packagings-list",
        path: "/app/packagings/listado",
        title: "sidenav.consumables.packagings",
        icon: CodeSandboxOutlined,
        breadcrumb: true,
        submenu: [],
      },
    ],
  },
];

const overheadsNavTree = [
  {
    key: "overheads",
    path: "/app/gastos",
    title: "sidenav.overheads",
    icon: IdcardOutlined,
    breadcrumb: false,
    submenu: [
      {
        key: "overheads-list",
        path: "/app/gastos/listado",
        title: "sidenav.overheads.list",
        icon: IdcardOutlined,
        breadcrumb: true,
        submenu: [],
      },
    ],
  },
];

/* -------------- */

const navigationConfig = [
  ...dashBoardNavTree,
  ...ordersNavTree,
  ...productsNavTree,
  ...consumablesNavTree,
  ...recipesNavTree,
  ...clientsNavTree,
  ...purchaseNavTree,
  ...providersNavTree,
  ...usersNavTree,
  ...overheadsNavTree,
];

export default navigationConfig;
