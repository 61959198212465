import React from "react";
import {Avatar, Dropdown, Menu} from "antd";
import {LogoutOutlined, SettingOutlined} from '@ant-design/icons';
import Icon from 'components/util-components/Icon';
import {useDispatch, useSelector} from "react-redux";
import {signOut} from "redux/actions/Auth";
import utils from "utils";

const menuItem = [
    {
        title: "Configuración",
        icon: SettingOutlined,
        path: "/app/usuarios/configuracion"
    }
]

export const NavProfile = () => {
    const dispatch = useDispatch();
    const {user} = useSelector(state => state.auth)
    const profileMenu = (
        <div className="nav-profile nav-dropdown">
            <div className="nav-profile-header">
                <div className="d-flex">
                    <Avatar size={40} className="font-size-md"
                            style={{backgroundColor: "#cbaa71"}}>
                        {utils.getNameInitial(user.name)}
                    </Avatar>
                    <div className="pl-3">
                        <h4 className="mb-0">{user.name}</h4>
                        <span className="text-muted">{user.email}</span>
                    </div>
                </div>
            </div>
            <div className="nav-profile-body">
                <Menu>
                    {menuItem.map((el, i) => {
                        return (
                            <Menu.Item key={i}>
                                <a href={el.path}>
                                    <Icon className="mr-3" type={el.icon}/>
                                    <span className="font-weight-normal">{el.title}</span>
                                </a>
                            </Menu.Item>
                        );
                    })}
                    <Menu.Item key={menuItem.length + 1} onClick={() => dispatch(signOut())}>
                        <span>
                          <LogoutOutlined className="mr-3"/>
                          <span className="font-weight-normal">Cerrar sesión</span>
                        </span>
                    </Menu.Item>
                </Menu>
            </div>
        </div>
    );
    return (
        <Dropdown placement="bottomRight" overlay={profileMenu} trigger={["click"]}>
            <Menu className="d-flex align-item-center" mode="horizontal">
                <Menu.Item>
                    <Avatar style={{backgroundColor: "#cbaa71"}} size={40}
                            className="font-size-md">
                        {utils.getNameInitial(user.name)}
                    </Avatar>
                </Menu.Item>
            </Menu>
        </Dropdown>
    );
}

export default NavProfile
