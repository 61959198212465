import React, {lazy, Suspense} from "react";
import {Redirect, Route, Switch} from "react-router-dom";
import Loading from 'components/shared-components/Loading';

export const AppViews = ({match}) => {
    return (
        <Suspense fallback={<Loading cover="page"/>}>
            <Switch>
                <Route path={`${match.url}/acceder`} component={lazy(() => import(`./authentication/login`))}/>
                <Route path={`${match.url}/olvide-contrasena`}
                       component={lazy(() => import(`./authentication/forgot-password`))}/>
                <Route path={`${match.url}/restablecer-contrasena`}
                       component={lazy(() => import(`./authentication/reset-password`))}/>
                <Redirect from={`${match.url}`} to={`${match.url}/acceder`}/>
            </Switch>
        </Suspense>
    )
}

export default AppViews;

