import {combineReducers} from 'redux';
import Auth from './Auth';
import Theme from './Theme';
import Notifications from "./Notifications";
import Print from "./Print";

const reducers = combineReducers({
    theme: Theme,
    auth: Auth,
    notifications: Notifications,
    print: Print
});

export default reducers;
