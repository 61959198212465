import {SET_AVAILABLE_PRINTERS, SET_SELECTED_PRINTER} from "../constants/Print";

export const setSelectedPrinter = (printer) => {
    return {
        type: SET_SELECTED_PRINTER,
        payload: printer
    }
};

export const setAvailablePrinters = (printers) => {
    return {
        type: SET_AVAILABLE_PRINTERS,
        payload: printers
    }
};
