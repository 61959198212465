import {all, call, fork, put, takeEvery} from 'redux-saga/effects';
import {
    OUT_OF_STOCK_NOTIFICATION,
    OUT_OF_STOCK_INGREDIENT_NOTIFICATION,
    OUT_OF_STOCK_PACKAGING_NOTIFICATION, OVERHEADS_NOTIFICATION, DEBTORS_NOTIFICATION
} from "../constants/Notifications";

export function* outOfStockNotification() {
    yield takeEvery(OUT_OF_STOCK_NOTIFICATION, function* (action) {
        localStorage.setItem(OUT_OF_STOCK_NOTIFICATION, JSON.stringify(action.payload));
    });
}

export function* outOfStockIngredientNotification() {
    yield takeEvery(OUT_OF_STOCK_INGREDIENT_NOTIFICATION, function* (action) {
        localStorage.setItem(OUT_OF_STOCK_INGREDIENT_NOTIFICATION, JSON.stringify(action.payload));
    });
}

export function* outOfStockPackagingNotification() {
    yield takeEvery(OUT_OF_STOCK_PACKAGING_NOTIFICATION, function* (action) {
        localStorage.setItem(OUT_OF_STOCK_PACKAGING_NOTIFICATION, JSON.stringify(action.payload));
    });
}

export function* overheadsNotification() {
    yield takeEvery(OVERHEADS_NOTIFICATION, function* (action) {
        localStorage.setItem(OVERHEADS_NOTIFICATION, JSON.stringify(action.payload));
    });
}

export function* debtorsNotification() {
    yield takeEvery(DEBTORS_NOTIFICATION, function* (action) {
        localStorage.setItem(DEBTORS_NOTIFICATION, JSON.stringify(action.payload));
    });
}

export default function* rootSaga() {
    yield all([
        fork(outOfStockNotification),
        fork(outOfStockIngredientNotification),
        fork(outOfStockPackagingNotification),
        fork(overheadsNotification),
        fork(debtorsNotification),
    ]);
}
