const dev = {
    API_ENDPOINT_URL: 'http://localhost:3000/api/v1',
    PUSHER_APP_KEY: '6c1ba21dddc7a2b643c8',
    PUSHER_APP_CLUSTER: 'mt1'
};

const prod = {
    API_ENDPOINT_URL: 'https://api.saborescaseros.com.uy/api/v1',
    PUSHER_APP_KEY: '93b0ebe3d8aab5d5f42c',
    PUSHER_APP_CLUSTER: 'mt1'
};

const test = {
    API_ENDPOINT_URL: 'https://api.test.com'
};

const getEnv = () => {
    switch (process.env.NODE_ENV) {
        case 'development':
            return dev
        case 'production':
            return prod
        case 'test':
            return test
        default:
            break;
    }
}

export const env = getEnv()
