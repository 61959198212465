import axios from "../configs/HttpConfig";
import {AUTH_CREDENTIALS} from "../redux/constants/Auth";

const AUTH_BASE_PATH = "/auth";


const addDefaultHeaders = () => {
    axios.defaults.headers = {
        ...axios.defaults.headers,
        ...JSON.parse(localStorage.getItem(AUTH_CREDENTIALS))
    }
}

const JwtAuthService = {}

JwtAuthService.login = (data) => {
    return axios.post(AUTH_BASE_PATH + '/sign_in', data);
}

JwtAuthService.signOut = () => {
    addDefaultHeaders();
    return axios.delete(AUTH_BASE_PATH + '/sign_out');
}

JwtAuthService.signUp = (data) => {
    return axios.post(AUTH_BASE_PATH, data);
}

JwtAuthService.forgotPassword = (data) => {
    return axios.post(AUTH_BASE_PATH + '/password', data);
}

JwtAuthService.resetPassword = (data, headers) => {
    return axios.put(AUTH_BASE_PATH + '/password', data, {
        headers
    });
}
/*
JwtAuthService.delete = (uid) => {
    addDefaultHeaders();
    return axios.delete(AUTH_BASE_PATH , uid);
}*/

JwtAuthService.changeUserData = (data) => {
    addDefaultHeaders();
    return axios.put(AUTH_BASE_PATH + '/', data);
}

export default JwtAuthService;
