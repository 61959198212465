import React, {useEffect} from "react";
import {Redirect, Route, Switch, withRouter} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import AppLayout from "layouts/app-layout";
import AuthLayout from 'layouts/auth-layout';
import AppLocale from "lang";
import {IntlProvider} from "react-intl";
import {ConfigProvider} from 'antd';
import qz from "qz-tray";
import {setAvailablePrinters} from "../redux/actions/Print";
import {signOut} from "../redux/actions/Auth";
import {useIdleTimer} from "react-idle-timer";
import {MAX_IDLE_TIME} from "../configs/AppConfig";

function RouteInterceptor({children, isAuthenticated, ...rest}) {
    return (
        <Route
            {...rest}
            render={({location}) =>
                isAuthenticated ? (
                    children
                ) : (
                    <Redirect
                        to={{
                            pathname: "/auth",
                            state: {from: location}
                        }}
                    />
                )
            }
        />
    );
}

export const Views = (props) => {
    const {auth_credentials} = useSelector(state => state.auth);
    const {locale} = useSelector(state => state.theme);
    const {location} = props;
    const currentAppLocale = AppLocale[locale];


    const dispatch = useDispatch();

    useEffect(() => {
        qz.websocket.connect().then(() => {
            return qz.printers.find();
        }).then(res => {
            dispatch(setAvailablePrinters(res))
            console.log(res)
        }).catch(err => {
            console.log(err)
        })
    }, [])


    const handleOnIdle = event => {
        dispatch(signOut())
        console.log('user is idle', event)
        console.log('last active', getLastActiveTime())
    }


    const {getLastActiveTime} = useIdleTimer({
        timeout: MAX_IDLE_TIME * 1000,
        onIdle: handleOnIdle,
        debounce: 500
    })


    return (
        <IntlProvider
            locale={currentAppLocale.locale}
            messages={currentAppLocale.messages}>
            <ConfigProvider locale={currentAppLocale.antd}>
                <Switch>
                    <Route exact path="/">
                        <Redirect to="/app"/>
                    </Route>
                    <Route path="/auth">
                        <AuthLayout/>
                    </Route>
                    <RouteInterceptor path="/app" isAuthenticated={auth_credentials}>
                        <AppLayout location={location}/>
                    </RouteInterceptor>
                </Switch>
            </ConfigProvider>
        </IntlProvider>
    )
}


export default withRouter(Views);
