import {
    AUTH_CREDENTIALS,
    AUTHENTICATED,
    HIDE_AUTH_MESSAGE,
    SHOW_AUTH_MESSAGE,
    SHOW_LOADING,
    SIGNIN_WITH_FACEBOOK_AUTHENTICATED,
    SIGNIN_WITH_GOOGLE_AUTHENTICATED,
    SIGNOUT_SUCCESS,
    SIGNUP_SUCCESS,
    USER_DATA
} from '../constants/Auth';

const initState = {
    loading: false,
    message: '',
    showMessage: false,
    redirect: '',
    auth_credentials: localStorage.getItem(AUTH_CREDENTIALS) ? JSON.parse(localStorage.getItem(AUTH_CREDENTIALS)) : null,
    user: localStorage.getItem(USER_DATA) ? JSON.parse(localStorage.getItem(USER_DATA)) : null
}

const auth = (state = initState, action) => {
    switch (action.type) {
        case AUTHENTICATED:
            return {
                ...state,
                loading: false,
                redirect: '/',
                user: action.payload.user,
                auth_credentials: action.payload.auth_credentials
            }
        case SHOW_AUTH_MESSAGE:
            return {
                ...state,
                message: action.message,
                showMessage: true,
                loading: false
            }
        case HIDE_AUTH_MESSAGE:
            return {
                ...state,
                message: '',
                showMessage: false,
            }
        case SIGNOUT_SUCCESS: {
            return {
                ...state,
                user: null,
                auth_credentials: null,
                redirect: '/',
                loading: false
            }
        }
        case SIGNUP_SUCCESS: {
            return {
                ...state,
                loading: false,
                token: action.token
            }
        }
        case SHOW_LOADING: {
            return {
                ...state,
                loading: true
            }
        }
        case SIGNIN_WITH_GOOGLE_AUTHENTICATED: {
            return {
                ...state,
                loading: false,
                token: action.token
            }
        }
        case SIGNIN_WITH_FACEBOOK_AUTHENTICATED: {
            return {
                ...state,
                loading: false,
                token: action.token
            }
        }
        default:
            return state;
    }
}

export default auth
