import {SET_AVAILABLE_PRINTERS, SET_SELECTED_PRINTER} from "../constants/Print";

const initState = {
    availablePrinters: null,
    selectedPrinter: null
}

const printReducer = (state = initState, action) => {
    switch (action.type) {
        case SET_AVAILABLE_PRINTERS:
            return {
                ...state,
                availablePrinters: action.payload,
            }
        case SET_SELECTED_PRINTER:
            return {
                ...state,
                selectedPrinter: action.payload,
            }
        default:
            return state;
    }
}

export default printReducer
