export const STATES = [
    "MONTEVIDEO",
    "ARTIGAS",
    "MALDONADO",
    "LAVALLEJA",
    "FLORES",
    "TREINTA Y TRES",
    "TACUAREMBO",
    "RIVERA",
    "SALTO",
    "ROCHA",
    "SORIANO",
    "RIO NEGRO",
    "DURAZNO",
    "PAYSANDU",
    "CANELONES",
    "SAN JOSE",
    "FLORIDA",
    "COLONIA",
    "CERRO LARGO",
]

export const MEASURE_UNITS = [
    "GRAMOS",
    "MILILITROS",
    "UNIDADES",
    "PORCIONES"
]

export const CURRENCIES = [
    {
        key: "USD",
        value: "Dólares Americanos"
    },
    {
        key: "UYU",
        value: "Pesos Uruguayos"
    }
]

export const CHANNELS = [
    "TELEFONO",
    "WHATSAPP",
    "INSTAGRAM",
    "FACEBOOK",
    "LINKEDIN"
]

export const ZONE = [
    {
        id: 0,
        name: "RETIRO EN LOCAL",
        cost: 0
    },
    {
        id: 1,
        name: "CARRASCO",
        cost: 150
    },
    {
        id: 2,
        name: "CENTRO",
        cost: 200
    },
    {
        id: 3,
        name: "CIUDAD DE LA COSTA",
        cost: 150
    },
    {
        id: 4,
        name: "CORDON",
        cost: 200
    },
    {
        id: 5,
        name: "COSTA DE ORO",
        cost: 150
    },
    {
        id: 6,
        name: "EL PINAR",
        cost: 150
    },
    {
        id: 7,
        name: "JOSE IGNACIO",
        cost: 250
    },
    {
        id: 8,
        name: "LA BARRA",
        cost: 220
    },
    {
        id: 9,
        name: "LA BLANQUEADA",
        cost: 200
    },
    {
        id: 10,
        name: "LA TAHONA",
        cost: 150
    },
    {
        id: 11,
        name: "MALDONADO",
        cost: 200
    },
    {
        id: 12,
        name: "MALVIN",
        cost: 150
    },
    {
        id: 13,
        name: "MANANTIALES",
        cost: 220
    },
    {
        id: 14,
        name: "PAN DE AZUCAR",
        cost: 180
    },
    {
        id: 15,
        name: "PARQUE MIRAMAR",
        cost: 150
    },
    {
        id: 16,
        name: "PIRIAPOLIS",
        cost: 180
    },
    {
        id: 17,
        name: "POCITOS",
        cost: 180
    },
    {
        id: 18,
        name: "PUNTA CARRETAS",
        cost: 180
    },
    {
        id: 19,
        name: "PUNTA DEL ESTE",
        cost: 200
    },

    {
        id: 20,
        name: "PUNTA GORDA",
        cost: 150
    },
]

export const STATUSORDER = [
    "INGRESADO",
    "REPARTO",
    "ENTREGADO",
]

export const AVAILABILITIES = [
    {
        key: "ALL",
        status: "gray",
        label: "Todos los estados"
    },
    {
        key: "UNAVAILABLE",
        label: "SIN STOCK",
        status: "red"
    },
    {
        key: "LIMITED_STOCK",
        label: "STOCK LIMITADO",
        status: "warning"
    },
    {
        key: "AVAILABLE",
        label: "EN STOCK",
        status: "success"
    }
]

export const PAYMENT_KINDS = [
    "TRANSFERENCIA",
    "CONTADO",
    "CREDITO",
]

export const PAYMENT_METHODS = [
    "CHEQUE",
    "CONTADO",
    "ANTICIPADO",
    "TRANSFERENCIA",
]

export const RECIPE_UNIT = [
    "PORCIONES",
    "GRAMOS",
]

export const SUPPLIES_TYPES = [
    "INGREDIENT",
    "RECIPE",
    "SUPPLY",
]

export const OVERHEADS_STATUS = [
    "PAGO",
    "NO PAGO",
]

export const TRANSACTIONS_TYPES = [
    "ENTRADA",
    "SALIDA",
]

export const REGISTERS_TYPE = [
    "CASH",
    "BANK",
]

export const REGISTERS_ACTIONS_TYPES = [
    "OPEN",
    "CLOSE"
]

