import React, { useEffect, useState } from "react";
import { Avatar, Badge, Dropdown, List, Menu } from "antd";
import {
  BellOutlined,
  CheckCircleOutlined,
  MailOutlined,
  WarningOutlined,
} from "@ant-design/icons";
import Flex from "components/shared-components/Flex";
import PusherNotifications from "configs/PusherConfig";
import { useDispatch, useSelector } from "react-redux";
import { outOfStockNotification } from "../../redux/actions";
import { GiChickenOven } from "react-icons/gi";

const getIcon = (icon) => {
  switch (icon) {
    case "mail":
      return <MailOutlined />;
    case "alert":
      return <WarningOutlined />;
    case "check":
      return <CheckCircleOutlined />;
    default:
      return <MailOutlined />;
  }
};

const getNotificationBody = (list) => {
  return list ? (
    <List
      size="small"
      itemLayout="horizontal"
      dataSource={list}
      renderItem={(item) => (
        <List.Item className="list-clickable">
          <Flex className="w-100" alignItems="center" justifyContent="between">
            <Flex flexDirection="row">
              {item.images[0] ? (
                <Avatar src={item.images[0].service_url} />
              ) : (
                <Avatar
                  className={`ant-avatar-${item.type}`}
                  icon={getIcon(item.icon)}
                />
              )}
              <Flex flexDirection="column" className="ml-3">
                <span className="font-weight-bold text-dark">{item.name} </span>
                <span className="text-gray-light">{item.category.name}</span>
              </Flex>
            </Flex>
            <Badge showZero count={`${item.available_quantity}`} />
          </Flex>
        </List.Item>
      )}
    />
  ) : (
    <div className="empty-notification">
      <img
        src="https://gw.alipayobjects.com/zos/rmsportal/sAuJeJzSKbUmHfBQRzmZ.svg"
        alt="empty"
      />
      <p className="mt-3">No tienes notificaciones</p>
    </div>
  );
};

export const NavNotification = () => {
  const dispatch = useDispatch();
  const products = useSelector((state) => state.notifications.products);

  const [visible, setVisible] = useState(false);

  const handleVisibleChange = (flag) => {
    setVisible(flag);
  };

  useEffect(() => {
    subscribeToOutOfStock();
  }, []);

  const subscribeToOutOfStock = () => {
    let channel = PusherNotifications.subscribe("product-stock-revision");
    channel.bind("out-of-stock", (data) => {
      dispatch(outOfStockNotification(data));
    });
  };

  const notificationList = (
    <div className="nav-dropdown nav-notification">
      <div className="nav-notification-header d-flex justify-content-between align-items-center">
        <h4 className="mb-0">Productos</h4>
      </div>
      <div className="nav-notification-body">
        {getNotificationBody(products)}
      </div>
    </div>
  );

  return (
    <Dropdown
      className="pt-4"
      placement="bottomRight"
      overlay={notificationList}
      onVisibleChange={handleVisibleChange}
      visible={visible}
      trigger={["click"]}
    >
      <Menu mode="horizontal">
        <Menu.Item>
          {products && (
            <Badge count={products.length}>
              <GiChickenOven size="30" />
            </Badge>
          )}
        </Menu.Item>
      </Menu>
    </Dropdown>
  );
};

export default NavNotification;
