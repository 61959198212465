import {
    OUT_OF_STOCK_NOTIFICATION,
    OUT_OF_STOCK_INGREDIENT_NOTIFICATION,
    OUT_OF_STOCK_PACKAGING_NOTIFICATION, OVERHEADS_NOTIFICATION, DEBTORS_NOTIFICATION
} from "../constants/Notifications";

const initState = {
    products: localStorage.getItem(OUT_OF_STOCK_NOTIFICATION) ? JSON.parse(localStorage.getItem(OUT_OF_STOCK_NOTIFICATION)) : null,
    ingredients: localStorage.getItem(OUT_OF_STOCK_INGREDIENT_NOTIFICATION) ? JSON.parse(localStorage.getItem(OUT_OF_STOCK_INGREDIENT_NOTIFICATION)) : null,
    packages: localStorage.getItem(OUT_OF_STOCK_PACKAGING_NOTIFICATION) ? JSON.parse(localStorage.getItem(OUT_OF_STOCK_PACKAGING_NOTIFICATION)) : null,
    overheads: localStorage.getItem(OVERHEADS_NOTIFICATION) ? JSON.parse(localStorage.getItem(OVERHEADS_NOTIFICATION)) : null,
    debtors: localStorage.getItem(DEBTORS_NOTIFICATION) ? JSON.parse(localStorage.getItem(DEBTORS_NOTIFICATION)) : null
}

const notifications = (state = initState, action) => {
    switch (action.type) {
        case OUT_OF_STOCK_NOTIFICATION:
            return {
                ...state,
                products: action.payload,
            }
        case OUT_OF_STOCK_INGREDIENT_NOTIFICATION:
            return {
                ...state,
                ingredients: action.payload,
            }
        case OUT_OF_STOCK_PACKAGING_NOTIFICATION:
            return {
                ...state,
                packages: action.payload,
            }
        case OVERHEADS_NOTIFICATION:
            return {
                ...state,
                packages: action.payload,
            }
        case DEBTORS_NOTIFICATION:
            return {
                ...state,
                debtors: action.payload,
            }
        default:
            return state;
    }
}

export default notifications

