import React, { lazy, Suspense } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import Loading from "components/shared-components/Loading";

export const AppViews = ({ match }) => {
  return (
    <Suspense fallback={<Loading cover="content" />}>
      <Switch>
        <Route
          path={`${match.url}/dashboards`}
          component={lazy(() => import(`./dashboards`))}
        />
        <Route
          path={`${match.url}/productos`}
          component={lazy(() => import(`./products`))}
        />
        <Route
          path={`${match.url}/proveedores`}
          component={lazy(() => import(`./providers`))}
        />
        <Route
          path={`${match.url}/ingredientes`}
          component={lazy(() => import(`./ingredients`))}
        />
        <Route
          path={`${match.url}/pedidos`}
          component={lazy(() => import(`./orders`))}
        />
        <Route
          path={`${match.url}/usuarios`}
          component={lazy(() => import(`./users`))}
        />

        <Route
          path={`${match.url}/clientes/deudores`}
          component={lazy(() => import(`./clients/debtors`))}
        />
        <Route
          path={`${match.url}/clientes`}
          component={lazy(() => import(`./clients`))}
        />
        <Route
          path={`${match.url}/compras`}
          component={lazy(() => import(`./purchases`))}
        />
        <Route
          path={`${match.url}/recetas`}
          component={lazy(() => import(`./recipes`))}
        />
        <Route
          path={`${match.url}/packagings`}
          component={lazy(() => import(`./packagings`))}
        />
        <Route
          path={`${match.url}/insumos`}
          component={lazy(() => import(`./manufactured-consumables`))}
        />
        <Route
          path={`${match.url}/gastos`}
          component={lazy(() => import(`./overheads`))}
        />
        <Route
          path={`${match.url}/caja`}
          component={lazy(() => import(`./registers`))}
        />
        <Route
          path={`${match.url}/promociones`}
          component={lazy(() => import(`./promotions`))}
        />
        <Redirect from={`${match.url}`} to={`${match.url}/dashboards`} />
      </Switch>
    </Suspense>
  );
};

export default AppViews;
