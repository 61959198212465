import React, { useEffect, useState } from "react";
import { Badge, Dropdown, List, Menu } from "antd";
import Flex from "components/shared-components/Flex";
import PusherNotifications from "configs/PusherConfig";
import { useDispatch, useSelector } from "react-redux";
import { outOfStockPackagingNotification } from "../../redux/actions";
import Utils from "../../utils";
import { GoPackage } from "react-icons/go";

const getNotificationBody = (list) => {
  return list ? (
    <List
      size="small"
      itemLayout="horizontal"
      dataSource={list}
      renderItem={(item) => (
        <List.Item className="list-clickable">
          <Flex className="w-100" alignItems="center" justifyContent="between">
            <Flex flexDirection="column" className="mr-3">
              <span className="font-weight-bold text-dark">{item.name} </span>
              <span className="text-gray-light">{item.category.name}</span>
            </Flex>
            <div className="badgeNotification">
              {item.total_available_measure +
                " " +
                Utils.getParsedUnit(item.measure_unit)}
            </div>
          </Flex>
        </List.Item>
      )}
    />
  ) : (
    <div className="empty-notification">
      <img
        src="https://gw.alipayobjects.com/zos/rmsportal/sAuJeJzSKbUmHfBQRzmZ.svg"
        alt="empty"
      />
      <p className="mt-3">No tienes notificaciones</p>
    </div>
  );
};

export const NavNotification = () => {
  const dispatch = useDispatch();
  const packages = useSelector((state) => state.notifications.packages);

  const [visible, setVisible] = useState(false);

  const handleVisibleChange = (flag) => {
    setVisible(flag);
  };

  useEffect(() => {
    subscribeToOutOfStock();
  }, []);

  const subscribeToOutOfStock = () => {
    let channel = PusherNotifications.subscribe("packaging-stock-revision");
    channel.bind("out-of-stock", (data) => {
      dispatch(outOfStockPackagingNotification(data));
    });
  };

  const notificationList = (
    <div className="nav-dropdown nav-notification">
      <div className="nav-notification-header d-flex justify-content-between align-items-center">
        <h4 className="mb-0">Packaging</h4>
      </div>
      <div className="nav-notification-body">
        {getNotificationBody(packages)}
      </div>
    </div>
  );

  return (
    <Dropdown
      className="pt-4"
      placement="bottomRight"
      overlay={notificationList}
      onVisibleChange={handleVisibleChange}
      visible={visible}
      trigger={["click"]}
    >
      <Menu mode="horizontal">
        <Menu.Item>
          {packages && (
            <Badge count={packages.length}>
              <GoPackage size="30" />
            </Badge>
          )}
        </Menu.Item>
      </Menu>
    </Dropdown>
  );
};

export default NavNotification;
