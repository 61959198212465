import React, {useEffect, useState} from 'react';
import {Badge, Dropdown, List, Menu} from 'antd';
import Flex from 'components/shared-components/Flex'
import PusherNotifications from 'configs/PusherConfig'
import {useDispatch, useSelector} from "react-redux";
import {overheadsNotification} from "../../redux/actions";
import {GiTakeMyMoney} from "react-icons/gi";
import moment from "moment";


const getNotificationBody = list => {
    return list ?
        <List
            size="small"
            itemLayout="horizontal"
            dataSource={list}
            renderItem={item => (
                <List.Item className="list-clickable">
                    <Flex className="w-100" alignItems="center" justifyContent="between">
                        <Flex flexDirection="column" className="mr-3">
                            <span className="font-weight-bold text-dark">{item.description} </span>
                            <span className="text-gray-light">VENCIMIENTO</span>
                        </Flex>
                        <div
                            className="badgeNotification">{moment(item.expiration_date.toString()).format("DD MMM  YYYY").toUpperCase()}</div>
                    </Flex>
                </List.Item>
            )}
        />
        :
        <div className="empty-notification">
            <img src="https://gw.alipayobjects.com/zos/rmsportal/sAuJeJzSKbUmHfBQRzmZ.svg" alt="empty"/>
            <p className="mt-3">No tienes notificaciones</p>
        </div>;
}

export const NotificationOverheads = () => {

    const dispatch = useDispatch()
    const overheads = useSelector(state => state.notifications.overheads);

    const [visible, setVisible] = useState(false);

    const handleVisibleChange = (flag) => {
        setVisible(flag);
    }

    useEffect(() => {
        subscribeToOutOfStock()
    }, [])

    const subscribeToOutOfStock = () => {
        let channel = PusherNotifications.subscribe('overheads-revision');
        channel.bind('expiration', (data) => {
            dispatch(overheadsNotification(data))
        });
    }

    const notificationList = (
        <div className="nav-dropdown nav-notification">
            <div className="nav-notification-header d-flex justify-content-between align-items-center">
                <h4 className="mb-0">Gastos fijos</h4>
            </div>
            <div className="nav-notification-body">
                {getNotificationBody(overheads)}
            </div>
        </div>
    );

    return (
        <Dropdown
            className="pt-4"
            placement="bottomRight"
            overlay={notificationList}
            onVisibleChange={handleVisibleChange}
            visible={visible}
            trigger={['click']}
        >
            <Menu mode="horizontal">
                <Menu.Item>
                    {
                        overheads && <Badge count={overheads.length}>
                            <GiTakeMyMoney size='30'/>
                        </Badge>
                    }

                </Menu.Item>
            </Menu>
        </Dropdown>
    )
}


export default NotificationOverheads;
