import React from 'react';
import {Provider} from 'react-redux';
import store from './redux/store';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import Views from './views';
import "moment/locale/es";
import moment from "moment";
import * as timeago from 'timeago.js';
import es from 'timeago.js/lib/lang/es';

// register it.


function App() {
    timeago.register('es', es);
    moment.locale('es');

    return (
        <div className="App">
            <Provider store={store}>
                <Router>
                    <Switch>
                        <Route path="/" component={Views}/>
                    </Switch>
                </Router>
            </Provider>
        </div>
    );
}

export default App;
