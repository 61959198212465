import {NAV_TYPE_SIDE} from 'constants/ThemeConstant';
import {env} from './EnvironmentConfig'
import {SIDE_NAV_DARK} from "../constants/ThemeConstant";

export const APP_NAME = 'Sabores Caseros';
export const API_BASE_URL = env.API_ENDPOINT_URL

export const MAX_IDLE_TIME = 300; // in seconds

export const THEME_CONFIG = {
    navCollapsed: false,
    sideNavTheme: SIDE_NAV_DARK,
    locale: 'es',
    navType: NAV_TYPE_SIDE,
    topNavColor: '#3e82f7',
    headerNavColor: "black",
    mobileNav: false
};

export const ALERTS_TIME_TO_HIDE = 6000;
