import {all, call, fork, put, takeEvery} from 'redux-saga/effects';
import {
    AUTH_CREDENTIALS,
    AUTH_HEADERS,
    AUTH_TOKEN,
    SIGNIN,
    SIGNIN_WITH_FACEBOOK,
    SIGNIN_WITH_GOOGLE,
    SIGNOUT,
    SIGNUP,
    USER_DATA
} from '../constants/Auth';
import {
    authenticated,
    showAuthMessage,
    signInWithFacebookAuthenticated,
    signInWithGoogleAuthenticated,
    signOutSuccess,
    signUpSuccess
} from "../actions/Auth";

import FirebaseService from 'services/FirebaseService';
import JwtAuthService from "services/JwtAuthService";

export function* signInWithFBEmail() {
    yield takeEvery(SIGNIN, function* ({payload}) {
        try {
            const response = yield call(JwtAuthService.login, payload);
            const {headers} = response;
            const {data} = response.data;

            const auth_cred = {
                [AUTH_HEADERS.ACCESS_TOKEN]: headers[AUTH_HEADERS.ACCESS_TOKEN],
                [AUTH_HEADERS.CLIENT]: headers[AUTH_HEADERS.CLIENT],
                [AUTH_HEADERS.EXPIRY]: headers[AUTH_HEADERS.EXPIRY],
                [AUTH_HEADERS.UID]: headers[AUTH_HEADERS.UID],
                [AUTH_HEADERS.TOKEN_TYPE]: headers[AUTH_HEADERS.TOKEN_TYPE]
            }

            localStorage.setItem(AUTH_CREDENTIALS, JSON.stringify(auth_cred));
            localStorage.setItem(USER_DATA, JSON.stringify(data));

            yield put(authenticated({user: data, auth_credentials: auth_cred}));

        } catch (err) {
            if (err.response && err.response.data) {
                const {errors} = err.response.data;
                for (let i in errors) {
                    yield put(showAuthMessage(errors[i]));
                }
            } else {
                yield put(showAuthMessage("Ha ocurrido un error inesperado"));
            }

        }
    });
}

export function* signOut() {
    yield takeEvery(SIGNOUT, function* () {
        try {
            localStorage.removeItem(AUTH_CREDENTIALS);
            localStorage.removeItem(USER_DATA);
            yield put(signOutSuccess());
        } catch (err) {
            const {errors} = err.response.data;
            for (let i in errors) {
                yield put(showAuthMessage(errors[i]));
            }
        }
    });
}

export function* signUpWithFBEmail() {
    yield takeEvery(SIGNUP, function* ({payload}) {
            const {email, password} = payload;
            try {
                const user = yield call(FirebaseService.signUpEmailRequest, email, password);
                if (user.message) {
                    yield put(showAuthMessage(user.message));
                } else {
                    localStorage.setItem(AUTH_TOKEN, user.user.uid);
                    yield put(signUpSuccess(user.user.uid));
                }
            } catch (error) {
                yield put(showAuthMessage(error));
            }
        }
    );
}

export function* signInWithFBGoogle() {
    yield takeEvery(SIGNIN_WITH_GOOGLE, function* () {
        try {
            const user = yield call(FirebaseService.signInGoogleRequest);
            if (user.message) {
                yield put(showAuthMessage(user.message));
            } else {
                localStorage.setItem(AUTH_TOKEN, user.user.uid);
                yield put(signInWithGoogleAuthenticated(user.user.uid));
            }
        } catch (error) {
            yield put(showAuthMessage(error));
        }
    });
}

export function* signInWithFacebook() {
    yield takeEvery(SIGNIN_WITH_FACEBOOK, function* () {
        try {
            const user = yield call(FirebaseService.signInFacebookRequest);
            if (user.message) {
                yield put(showAuthMessage(user.message));
            } else {
                localStorage.setItem(AUTH_TOKEN, user.user.uid);
                yield put(signInWithFacebookAuthenticated(user.user.uid));
            }
        } catch (error) {
            yield put(showAuthMessage(error));
        }
    });
}

export default function* rootSaga() {
    yield all([
        fork(signInWithFBEmail),
        fork(signOut),
        fork(signUpWithFBEmail),
        fork(signInWithFBGoogle),
        fork(signInWithFacebook)
    ]);
}
