import antdEs from 'antd/es/locale/es_ES';
import esMsg from "../locales/es.json";

const EsLang = {
    antd: antdEs,
    locale: 'es',
    messages: {
        ...esMsg
    },
};
export default EsLang;
